html {
  scroll-behavior: smooth;
}

body {
  background-image: url("./imgs/pexels-francesco-ungaro-998641.jpg") !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-attachment: fixed !important;
}

#root {
  background-color: rgba(0, 0, 0, 0.25);
}

h1, h2, h3, h4, h5, h6, .title {
  font-family: 'Paytone One', Arial, Helvetica, sans-serif !important;
  color: rgb(255 255 255 / 85%);
}

h1 {font-size: 2.5em !important; font-weight: lighter !important;}
h2 {font-size: 2.25em !important; font-weight: lighter !important;}
h3 {font-size: 1.75em !important; font-weight: lighter !important;}
h4 {font-size: 1.35em !important; font-weight: lighter !important;}
h5 {font-size: 0.75em !important; font-weight: lighter !important;}
h6 {font-size: 0.5em !important; font-weight: lighter !important;}

p, .subtitle {
  font-family: 'PT Sans', Arial, Helvetica, sans-serif;
  color: rgb(255 255 255 / 80%);
}

li {
  color: rgb(255 255 255 / 80%);
}

.bold {
  font-weight: bold;
}

.portfolioItem {
  height: 300px;
  max-width: 100%;
  background-position-x: center;
  background-size: cover;
  transition: 10s all;
  transition-timing-function: linear;
  overflow: hidden;
}

/* .portfolioItem:hover {
  background-position: bottom;
  cursor: progress;
} */

/* .portfolioItem:hover .projectdetails{
  bottom: 0;
  transition: 0.5s linear;
} */

.projectdetails {
  background-color: rgba(26, 32, 44, 0.65);
  text-align: left;
  padding-left: 1em;
  padding-right: 1em;
}

.navigation {
  height: 60px;
  background-color: black;
  z-index: 9999;
  display: flex;
  align-items: center;
  padding: 1em;
  border-radius: 25px;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 10px;
}

.navigation ul {
  list-style-type: none;
  display: flex;
}

.navigation li {
  margin: 0 5px;
  height: 40px;
  width: 60px;
  display: flex;
  align-items: center;
}

.smooth-image-transition {
  transition: all 0.3s ease-in-out; 
}

/* .cta {
  width: 100px !important;
  border-radius: 20px;
  background: yellow;
  color: black;
  padding: 5px;
  display: flex;
  justify-content: center;
  font-weight: bold;
} */

.chakra-badge {
  margin-right: 5px;
}

.mobile-nav {
  display: none !important;
}

.desktop-nav ul {
  list-style-type: none;
  display: flex;
  gap: 1em;
  font-size: 1.15em;
  font-weight: bold;
}

.go-top {
	position: fixed;
	bottom: 2em;
	right: 2em;
	font-size: 12px;
	padding: 1em;
  flex-direction: column;
  align-items: center;
}

.go-top.hidden {
  display: none;
}
.go-top.visible{
  display: flex;
}

.contact form {
  margin: 1em 0px;
  padding: 2em;
  border-radius: 25px;
  border: 1px solid rgba(255, 255, 255, 0.7);
 }

.contact input, .contact select {
  margin-bottom: 10px;
}

.contact ::placeholder {
  color: rgba(255, 255, 255, 0.65);
}

@media screen and (max-width: 950px) {
  .mobile-nav {
    display: block !important;
  }

  .desktop-nav, .desktop-cta {
    display: none;
  }

  .title {
    font-size: 2.5em !important;
  }

  .subtitle, p {
    font-size: 1em !important;
  }

  .hide-on-mobile {
    display: none;
  }

  .portfolioItem:hover {
    background-position: top;
  }
}


/* .gradient-border {
  --borderWidth: 3px;
  background: #1D1F20;
  position: relative;
  border-radius: var(--borderWidth);
}
.gradient-border:after {
  content: '';
  position: absolute;
  top: calc(-1 * var(--borderWidth));
  left: calc(-1 * var(--borderWidth));
  height: calc(100% + var(--borderWidth) * 2);
  width: calc(100% + var(--borderWidth) * 2);
  background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
  border-radius: calc(2 * var(--borderWidth));
  z-index: -1;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}


@keyframes animatedgradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
} */


/* .gradient-border {
	background: #1d1f20;
	position: relative;
  border: none !important;
}

.gradient-border:after {
	content: '';
	position: absolute;
	top: calc(-1 * 3px);
	left: calc(-1 * 3px);
	height: calc(100% + 3px * 2);
	width: calc(100% + 3px * 2);
	background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
	border-radius: 25px;
	z-index: -1;
	animation: animatedgradient 3s ease alternate infinite;
	background-size: 300% 300%;
}

@keyframes animatedgradient {
	0% { background-position: 0% 50%; }
	50% { background-position: 100% 50%; }
	100% { background-position: 0% 50%; }
} */

#hubspot-form {
  background: white;
  max-width: 600px;
  margin: 0 auto;
  padding: 50px;
  border-radius: 50px;
  margin-bottom: 20px;
}